<template>
  <div>

    <!-- ----------------------------------------------------------------------------------- -->

    <v-dialog v-model="modals.create.display" max-width="600" scrollable @click:outside="createTask()" @input="createTask()">
      <v-card :loading="loading.card" v-if="modals.create.display">
        <v-card-title class="pa-4">
          <span>{{ modalName }}</span>
          <v-spacer></v-spacer>
          <v-btn color="error" icon @click="createTask()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pa-4 pt-8">
          <v-row>
            <v-col>
              <v-text-field hide-details outlined dense label="ФИО" v-model="forms.create.name"></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field hide-details outlined dense label="Организация" v-model="forms.create.organization"></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field v-mask="`+# (###) ### ## ##`" hide-details outlined dense label="Номер телефона" v-model="forms.create.phone"></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-textarea rows="3" hide-details outlined dense label="Описание" v-model="forms.create.comment"></v-textarea>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-textarea rows="3" hide-details outlined dense label="Используемые комплектующие или материалы" v-model="forms.create.materials"></v-textarea>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-textarea rows="3" hide-details outlined dense label="Выполненные работы" v-model="forms.create.works"></v-textarea>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-select
                dense
                outlined
                v-model="forms.create.status_id"
                label="Статус"
                :items="statuses"
                item-value="id"
                item-text="name"
                hide-details
              >
              </v-select>
            </v-col>
            <v-col cols="6">
              <v-dialog ref="dialogTaskDateStart" v-model="modals.filterDateStart.display" :return-value.sync="forms.create.date_start" width="290px">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field dense hide-details outlined v-model="forms.create.date_start" label="Дата создания" readonly clearable v-bind="attrs" v-on="on"></v-text-field>
                </template>
                <v-date-picker v-model="forms.create.date_start" scrollable>
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="modals.filterDateStart.display = false">Cancel</v-btn>
                  <v-btn text color="primary" @click="$refs.dialogTaskDateStart.save( forms.create.date_start )">OK</v-btn>
                </v-date-picker>
              </v-dialog>
            </v-col>
            <v-col cols="6">
              <v-dialog ref="dialogTaskDateEnd" v-model="modals.filterDateEnd.display" :return-value.sync="forms.create.date_end" width="290px">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field dense hide-details outlined v-model="forms.create.date_end" label="Дата завершения" readonly clearable v-bind="attrs" v-on="on"></v-text-field>
                </template>
                <v-date-picker v-model="forms.create.date_end" scrollable>
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="modals.filterDateEnd.display = false">Cancel</v-btn>
                  <v-btn text color="primary" @click="$refs.dialogTaskDateEnd.save( forms.create.date_end )">OK</v-btn>
                </v-date-picker>
              </v-dialog>
            </v-col>
            <v-col cols="6">
              <v-text-field v-model="forms.create.time_start" v-mask="`##:##`" label="Время создания" outlined clearable dense></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field v-model="forms.create.time_end" v-mask="`##:##`" label="Время создания" outlined clearable dense></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="pa-4">
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="saveTask" :disabled="!canSaveTask">Сохранить</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="modals.share.display" max-width="600" scrollable>
      <v-card :loading="loading.card">
        <v-card-title class="pa-4">
          <span>Поделиться</span>
          <v-spacer></v-spacer>
          <v-btn color="error" icon @click="modals.share.display = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pa-4 pt-8">
          <!--v-row>
            <v-col>
              <v-text-field ref="link" readonly hide-details outlined label="Ссылка" :value="shareLink"></v-text-field>
            </v-col>
          </v-row-->
          <v-row>
            <v-col>
              <v-textarea ref="text" readonly hide-details outlined rows="5" label="Текст" :value="shareText"></v-textarea>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="pa-4">
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="copyLink">Ссылка</v-btn>
          <v-btn text color="primary" @click="copyText">Текст</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="modals.settings.display" max-width="600" scrollable>
      <v-card :loading="loading.card">
        <v-card-title class="pa-4">
          <span>Настройки</span>
          <v-spacer></v-spacer>
          <v-btn color="error" icon @click="modals.settings.display = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pa-4 pt-8">
          <v-row>
            <v-col>
              <v-expansion-panels v-model="panels">

                <v-expansion-panel>
                  <v-expansion-panel-header>Статусы</v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-row>
                      <v-col>
                        <v-data-table
                          :loading="datatables.statuses.loading"
                          :headers="datatables.statuses.headers"
                          :items="statuses"
                          item-key="id"
                          checkbox-color="blue"
                          :search="datatables.statuses.search"
                          hide-default-footer
                          class="elevation-2"
                          dense
                        >
                          <template v-slot:item.actions="{ item }">
                            <v-btn icon color="error" @click="deleteStatuses( item )">
                              <v-icon>mdi-trash-can-outline</v-icon>
                            </v-btn>
                          </template>
                        </v-data-table>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <v-text-field outlined dense hide-details v-model="forms.create.name" label="Наименование"></v-text-field>
                      </v-col>
                      <v-col cols="auto">
                        <v-btn color="primary" @click="createStatus" :disabled="!canSaveStatus">Добавить</v-btn>
                      </v-col>
                    </v-row>
                  </v-expansion-panel-content>
                </v-expansion-panel>

                <v-expansion-panel>
                  <v-expansion-panel-header>Столбцы таблицы</v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-row>
                      <v-col>
                        <v-checkbox v-model="modals.settings.data.selected" v-for="item of modals.settings.data.headers" :key="item.value" :label="item.text" :value="item.value" hide-details></v-checkbox>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-spacer></v-spacer>
                      <v-col cols="auto">
                        <v-btn text color="primary" :disabled="!canSaveSettings" @click="saveSettings">Сохранить</v-btn>
                      </v-col>
                    </v-row>
                  </v-expansion-panel-content>
                </v-expansion-panel>

              </v-expansion-panels>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="pa-4">
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="modals.filter.display" max-width="600" scrollable>
      <v-card :loading="loading.card">
        <v-card-title class="pa-4">
          <span>Фильтр</span>
          <v-spacer></v-spacer>
          <v-btn color="error" icon @click="modals.filter.display = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pa-4 pt-8">
          <v-row>
            <v-col>
              <v-select v-model="modals.filter.data.status_id" dense outlined label="Статус" :items="statuses" item-value="id" item-text="name" clearable></v-select>
            </v-col>
            <v-col>
              <v-dialog ref="dialogFilterDateStart" v-model="modals.filterDateStart.display" :return-value.sync="modals.filter.data.date_start" width="290px">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field dense outlined v-model="modals.filter.data.date_start" label="Создано" readonly clearable v-bind="attrs" v-on="on"></v-text-field>
                </template>
                <v-date-picker color="primary" v-model="modals.filter.data.date_start" scrollable>
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="modals.filterDateStart.display = false">Cancel</v-btn>
                  <v-btn text color="primary" @click="$refs.dialogFilterDateStart.save( modals.filter.data.date_start )">OK</v-btn>
                </v-date-picker>
              </v-dialog>
            </v-col>
            <v-col>
              <v-dialog ref="dialogFilterDateEnd" v-model="modals.filterDateEnd.display" :return-value.sync="modals.filter.data.date_end" width="290px">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field dense outlined v-model="modals.filter.data.date_end" label="Завершенно" readonly clearable v-bind="attrs" v-on="on"></v-text-field>
                </template>
                <v-date-picker color="primary" v-model="modals.filter.data.date_end" scrollable>
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="modals.filterDateEnd.display = false">Cancel</v-btn>
                  <v-btn text color="primary" @click="$refs.dialogFilterDateEnd.save( modals.filter.data.date_end )">OK</v-btn>
                </v-date-picker>
              </v-dialog>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="pa-4">
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="filteringTasks">Применить</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- ----------------------------------------------------------------------------------- -->

    <v-card>
      <v-toolbar elevation="0" class="pa-0">
        <v-tabs v-model="tab" class="mr-4">
          <v-tab :disabled="loading.tab" href="#all">Все записи</v-tab>
          <v-tab :disabled="loading.tab" href="#archive">Архив</v-tab>
        </v-tabs>
        <v-spacer></v-spacer>

        <v-tooltip v-if="datatables.tasks.selected.length" bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn class="mr-4" icon color="error" :loading="loading.delete" :disabled="loading.delete" @click="deleteTasks" v-bind="attrs" v-on="on">
              <v-icon>mdi-trash-can-outline</v-icon>
            </v-btn>
          </template>
          <span>Удалить</span>
        </v-tooltip>

        <v-tooltip v-if="datatables.tasks.selected.length && tab === `all`" bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn class="mr-4" icon color="primary" :loading="loading.delete" :disabled="loading.delete" @click="createArchive" v-bind="attrs" v-on="on">
              <v-icon>mdi-archive-arrow-down-outline</v-icon>
            </v-btn>
          </template>
          <span>Добавить в архив</span>
        </v-tooltip>

        <v-tooltip v-if="datatables.tasks.selected.length && tab === `archive`" bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn class="mr-4" icon color="primary" :loading="loading.archive" :disabled="loading.archive" @click="deleteArchive" v-bind="attrs" v-on="on">
              <v-icon>mdi-archive-off-outline</v-icon>
            </v-btn>
          </template>
          <span>Убрать из архива</span>
        </v-tooltip>

        <v-tooltip v-if="!datatables.tasks.selected.length && tab === `all`" bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn class="mr-4" icon :color="isFilter ? 'primary' : null" @click="modals.filter.display = true" v-bind="attrs" v-on="on">
              <v-icon>mdi-filter</v-icon>
            </v-btn>
          </template>
          <span>Фильтр</span>
        </v-tooltip>

        <v-btn v-if="!datatables.tasks.selected.length && tab === `all`" class="mr-4" color="primary" text @click="createTask()">
          Добавить
        </v-btn>

        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on">
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item @click="modals.settings.display = true">
              <v-list-item-icon>
                <v-icon>mdi-cog</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Настройки</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-toolbar>

      <v-divider></v-divider>

      <v-toolbar elevation="0">
        <v-row>
          <v-col>
            <v-text-field hide-details outlined dense label="Поиск по таблице" v-model="datatables.tasks.search"></v-text-field>
          </v-col>
        </v-row>
      </v-toolbar>

      <v-divider></v-divider>

      <v-card-text class="pa-4">

        <v-data-table
          :loading="datatables.tasks.loading"
          v-model="datatables.tasks.selected"
          :headers="taskTableHeaders"
          :items="datatables.tasks.items"
          item-key="id"
          :checkbox-color="tab === `all` ? `blue` : null"
          :search="datatables.tasks.search"
          show-select
          hide-default-footer
          :items-per-page="-1"
          class="elevation-2"
        >
          <template v-slot:item.phone="{ item }">
            <a :href="`tel:${item.phone}`" class="inline-text">{{ item.phone }}</a>
          </template>
          <template v-slot:item.start="{ item }">
            <small class="font-weight-medium">
              <span class="inline-text">{{ item.date_start }}</span>
              <div>{{ item.time_start }}</div>
            </small>
          </template>
          <template v-slot:item.end="{ item }">
            <small class="font-weight-medium">
              <span class="inline-text">{{ item.date_end }}</span>
              <div>{{ item.time_end }}</div>
            </small>
          </template>
          <template v-slot:item.status="{ item }">
            <v-chip color="primary" small outlined><b>{{ item.status }}</b></v-chip>
          </template>
          <template v-slot:item.actions="{ item }">
            <div class="d-flex">

              <v-tooltip v-if="tab === `all`" bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon color="primary" @click="createTask( item )" v-bind="attrs" v-on="on">
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>Редактировать</span>
              </v-tooltip>


              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon @click="shareTask( item )" v-bind="attrs" v-on="on">
                    <v-icon>mdi-share</v-icon>
                  </v-btn>
                </template>
                <span>Поделиться</span>
              </v-tooltip>

            </div>
          </template>
        </v-data-table>
      </v-card-text>

      <v-snackbar v-model="snackbars.copy.display">
        {{ snackbars.copy.props.text }}
        <template v-slot:action="{ attrs, on }">
          <v-btn color="pink" text v-bind="attrs" v-on="on" @click="snackbars.copy.display = false"> Ok </v-btn>
        </template>
      </v-snackbar>

    </v-card>

    <!-- ----------------------------------------------------------------------------------- -->

  </div>
</template>

<script>

import { mapGetters, mapActions } from 'vuex'

export default {

  name: 'Index',

  data: () => ({

    url: 'https://support.supcom.ru/',

    snackbars: {
      copy: {
        props: {
          text: null
        },
        display: false
      }
    },

    tab: 'all',

    panels: 0,

    loading: {
      tab: false,
      delete: false,
      archive: false,
      card: false
    },

    modals: {
      create: {
        display: false
      },
      settings: {
        data: {
          headers: [
            { text: '№', value: 'id' },
            { text: 'ФИО', value: 'name' },
            { text: 'Организация', value: 'organization' },
            { text: 'Телефон', value: 'phone' },
            { text: 'Описание', value: 'comment' },
            { text: 'Статус', value: 'status' },
            { text: 'Создано', value: 'start' },
            { text: 'Завершено', value: 'end' },
            { text: 'Действия', value: 'actions' }
          ],
          selected: []
        },
        display: false
      },
      filter: {
        display: false,
        props: {},
        data: {}
      },
      filterDateStart: {
        display: false
      },
      filterDateEnd: {
        display: false
      },
      share: {
        display: false,
        props: {
          task: {}
        }
      }
    },

    forms: {
      create: {
        id: null
      }
    },

    datatables: {
      tasks: {
        loading: false,
        search: '',
        selected: [],
        headers: [
          { sortable: true, filterable: true, text: '№', value: 'id' },
          { sortable: false, filterable: true, text: 'ФИО', value: 'name' },
          { sortable: false, filterable: true, text: 'Организация', value: 'organization' },
          { sortable: false, filterable: true, text: 'Телефон', value: 'phone' },
          { sortable: false, filterable: true, text: 'Описание', value: 'comment' },
          { sortable: false, filterable: true, text: 'Статус', value: 'status' },
          { sortable: false, filterable: true, text: 'Создано', value: 'start' },
          { sortable: false, filterable: true, text: 'Завершено', value: 'end' },
          { sortable: false, filterable: false, text: 'Действия', value: 'actions' }
        ],
        items: [],
        current: null
      },
      statuses: {
        loading: false,
        search: '',
        selected: [],
        headers: [
          { sortable: false, text: 'Наименование', value: 'name' },
          { sortable: false, text: 'Действия', value: 'actions', width: 100, align: 'center' }
        ],
        items: []
      }
    },

    statuses: []

  }),

  computed: {
    ...mapGetters({
      headers: 'getHeaders'
    }),
    taskTableHeaders () {
      return this.datatables.tasks.headers.filter( header => this.headers.indexOf( header.value ) >= 0 )
    },
    modalName () {
      return this.forms.create.id ? 'Редактирование записи' : 'Добавление записи'
    },
    canSaveTask () {
      return Boolean( !this.loading.card && this.forms.create.status_id )
    },
    canSaveStatus () {
      return Boolean( this.forms.create.name )
    },
    canSaveSettings () {
      return this.modals.settings.data.selected.length > 1
    },
    shareLink () {
      if ( this.modals.share.props.task.id ) {
        return `${this.url}task/${this.modals.share.props.task.id}`
      }
      return null
    },
    shareText () {
      if ( this.modals.share.props.task.id ) {
        return `${this.url}task/${this.modals.share.props.task.id}\nФИО: ${this.modals.share.props.task.name || ''}\nОрганизация: ${this.modals.share.props.task.organization || ''}\nНомер телефона: ${this.modals.share.props.task.phone || ''}\nОписание: ${this.modals.share.props.task.comment || ''}`
      }
      return null
    },
    isFilter () {
      return ( this.modals.filter.data.date_start || this.modals.filter.data.date_end || this.modals.filter.data.status_id )
    }
  },

  methods: {

    ...mapActions({
      setHeaders: 'setHeaders'
    }),

    init () {
      this.readStatuses()
      this.filteringTasks()
    },

    async filteringTasks () {
      this.loading.tab = true
      this.datatables.tasks.loading = true
      let query = Object.assign( {}, this.modals.filter.data )
      for ( let item in query ) {
        if ( !query[ item ] ) {
          delete query[ item ]
        }
      }
      let tasks = await this.$api.filteringTasks( query )
      this.datatables.tasks.items = tasks.data
      this.datatables.tasks.loading = false
      this.loading.tab = false
    },

    async readStatuses () {
      this.datatables.statuses.loading = true
      let statuses = await this.$api.readStatuses()
      this.statuses = statuses.data
      this.datatables.statuses.loading = false
    },

    async readTasks () {
      this.loading.tab = true
      this.datatables.tasks.loading = true
      let tasks = await this.$api.readTasks()
      this.datatables.tasks.items = tasks.data
      this.datatables.tasks.loading = false
      this.loading.tab = false
    },

    async readArchive () {
      this.loading.tab = true
      this.datatables.tasks.loading = true
      let tasks = await this.$api.readArchive()
      this.datatables.tasks.items = tasks.data
      this.datatables.tasks.loading = false
      this.loading.tab = false
    },

    saveSettings () {
      this.setHeaders( this.modals.settings.data.selected.join( ',' ) )
    },

    createTask ( item = null ) {
      this.forms.create = Object.assign( {}, item )
      let date = new Date().toISOString().split( 'T' )[ 0 ]
      let time = new Date().toLocaleTimeString()
      if ( item ) {
        this.datatables.tasks.current = this.datatables.tasks.items.indexOf( item )
        this.forms.create = {
          ...this.forms.create,
          date_end: this.forms.create.date_end ? this.forms.create.date_end : date,
          time_end: this.forms.create.time_end ? this.forms.create.time_end : time
        }
      } else {
        this.forms.create = {
          ...this.forms.create,
          date_start: date,
          time_start: time
        }
      }
      this.modals.create.display = !this.modals.create.display
    },

    async saveTask () {
      this.loading.card = true
      if ( this.forms.create.id ) {
        let task = await this.$api.updateTasks( this.forms.create.id, this.forms.create )
        this.datatables.tasks.items.splice( this.datatables.tasks.current, 1, task.data )
      } else {
        let task = await this.$api.createTasks( this.forms.create )
        this.datatables.tasks.items.splice( 0, 0, task.data )
      }
      this.loading.card = false
      this.createTask()
    },

    async deleteTasks () {
      let ids = []
      for ( let task of this.datatables.tasks.selected ) {
        ids.push( task.id )
        this.datatables.tasks.items.splice( this.datatables.tasks.items.indexOf( task ), 1 )
      }
      this.loading.delete = true
      await this.$api.deleteTasks( ids.join( ',' ) )
      this.datatables.tasks.selected = []
      this.loading.delete = false
    },

    async createStatus () {
      let status = await this.$api.createStatuses( this.forms.create )
      this.statuses.splice( 0, 0, status.data )
      this.forms.create = {}
    },

    async deleteStatuses ( item ) {
      await this.$api.deleteStatuses( item.id )
      this.statuses.splice( this.statuses.indexOf( item ), 1 )
    },

    async createArchive () {
      let ids = []
      for ( let task of this.datatables.tasks.selected ) {
        ids.push( task.id )
        this.datatables.tasks.items.splice( this.datatables.tasks.items.indexOf( task ), 1 )
      }
      this.loading.archive = true
      await this.$api.createArchive( {
        ids: ids
      } )
      this.datatables.tasks.selected = []
      this.loading.archive = false
    },

    async deleteArchive () {
      let ids = []
      for ( let task of this.datatables.tasks.selected ) {
        ids.push( task.id )
        this.datatables.tasks.items.splice( this.datatables.tasks.items.indexOf( task ), 1 )
      }
      this.loading.archive = true
      await this.$api.deleteArchive( ids.join( ',' ) )
      this.datatables.tasks.selected = []
      this.loading.archive = false
    },

    shareTask ( task ) {
      this.modals.share.props.task = task
      this.modals.share.display = !this.modals.share.display
    },

    copyLink () {
      // говнокод наверное
      this.$refs.link.$refs.input.select()
      this.snackbars.copy.display = true
      this.snackbars.copy.props.text = `Ссылка скопирована`
      document.execCommand( 'copy' )
    },
    copyText () {
      // говнокод наверное
      this.$refs.text.$refs.input.select()
      this.snackbars.copy.display = true
      this.snackbars.copy.props.text = `Текст скопирован`
      document.execCommand( 'copy' )
    }

  },

  mounted () {
    this.modals.settings.data.selected = this.headers.split( ',' )
    this.init()
  },

  watch: {
    tab () {
      this.datatables.tasks.selected = []
      if ( this.tab === `all` ) {
        this.filteringTasks()
      }
      if ( this.tab === `archive` ) {
        this.readArchive()
      }
    }
  },

}

</script>

<style>
.inline-text {
  white-space: nowrap;
}
</style>
