import Vue from 'vue'

import router from './router'

import store from './store'

import vuetify from './plugins/vuetify'

import App from './App.vue'

import requestPlugin from './plugins/request'


import VueMask from 'v-mask'
Vue.use(VueMask)

Vue.use( requestPlugin )

new Vue({
  router,
  vuetify,
  render: h => h( App ),
  store
}).$mount( '#app' )
