<template>

  <v-app>

    <!--v-navigation-drawer app></v-navigation-drawer-->

    <!--v-app-bar app></v-app-bar-->

    <v-main>
      <v-container fluid class="app-main pa-4">
        <router-view
          v-if="token"
        ></router-view>
        <Login v-else></Login>
      </v-container>
    </v-main>

    <!--v-footer app></v-footer-->
  </v-app>

</template>

<script>

import Login from './views/Login'

import { mapGetters } from 'vuex'

export default {

  name: 'App',

  data: () => ({
    //
  }),

  computed: {
    ...mapGetters({
      token: 'getToken'
    })
  },

  components: {
    Login
  }

}

</script>

<style>

.app-main {
  margin: 0 auto;
  max-width: 1300px;
  height: 100%;
}

</style>