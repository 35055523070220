<template>
  <div>
    <v-card>
      <v-toolbar class="px-4" elevation="0">
        <h3 class="font-weight-bold">Заявка</h3>
        <v-chip v-if="task.id" class="ml-4" small outlined color="primary">Статус: {{ task.status }}</v-chip>
        <v-chip v-if="task.archived_at" class="ml-4" small color="red" text-color="white">Заявка в архиве</v-chip>
      </v-toolbar>

      <v-divider></v-divider>

      <v-card-text>
        <v-list>

          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>{{ task.name | isEmpty }}</v-list-item-title>
              <v-list-item-subtitle>ФИО</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>{{ task.organization | isEmpty }}</v-list-item-title>
              <v-list-item-subtitle>Организация</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>
                <a v-if="task.phone" :href="`tel:${task.phone}`" class="inline-text">{{ task.phone }}</a>
                <span v-else>{{ task.phone | isEmpty }}</span>
              </v-list-item-title>
              <v-list-item-subtitle>Номер телефона</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>{{ task.comment | isEmpty }}</v-list-item-title>
              <v-list-item-subtitle>Описание</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>{{ task.materials | isEmpty }}</v-list-item-title>
              <v-list-item-subtitle>Используемые комплектующие или материалы</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>{{ task.works | isEmpty }}</v-list-item-title>
              <v-list-item-subtitle>Выполненные работы</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>{{ task.status | isEmpty }}</v-list-item-title>
              <v-list-item-subtitle>Статус</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>{{ task.date_start | isEmpty }} {{ task.time_start}}</v-list-item-title>
              <v-list-item-subtitle>Создано</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>{{ task.date_end | isEmpty }} {{ task.time_end }}</v-list-item-title>
              <v-list-item-subtitle>Завершено</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

        </v-list>

      </v-card-text>
    </v-card>
  </div>
</template>

<script>

export default {

  name: 'Application',

  data: () => ({
    task: {}
  }),

  methods: {

    async init () {
      if ( this.$route.params.id  ) {
        let task = await this.$api.readTasks( this.$route.params.id )
        console.log( task )
        this.task = task.data
      }
    }

  },

  mounted () {
    this.init()
  },

  filters: {
    isEmpty ( value ) {
      return value ? value : 'Информация не указана'
    }
  }

}

</script>
