export default {
  install ( Vue ) { // options

    Vue.prototype.$api = {

      async execute ( method, path, data = null, options = {} ) {
        let access_token = localStorage.access_token
        let request = await fetch( `https://support.supcom.ru/api${path}`, {
          method: method,
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer: ${access_token}`
          },
          body: data ? JSON.stringify({
            ...data
          }) : null,
          ...options
        })
        request = request.json()
        return request
      },

      async post ( ...params ) {
        return await this.execute( 'POST', ...params )
      },

      async get ( ...params ) {
        return await this.execute( 'GET', ...params )
      },

      async patch ( ...params ) {
        return await this.execute( 'PUT', ...params )
      },

      async put ( ...params ) {
        return await this.execute( 'PUT', ...params )
      },

      async delete ( ...params ) {
        return await this.execute( 'DELETE', ...params )
      },

      // /authentication

      async authentication ( data ) {
        return await this.post( `/authentication`, data )
      },



      // CRUD /tasks

      async createTasks ( data ) {
        return await this.post( `/tasks`, data )
      },

      async readTasks ( id = null) {
        if ( id ) {
          return await this.get( `/tasks/${id}` )
        }
        return await this.get( `/tasks` )
      },

      async updateTasks ( id, data ) {
        return await this.put( `/tasks/${id}`, data )
      },

      async deleteTasks ( ids ) {
        return await this.delete( `/tasks/${ids}` )
      },
      async filteringTasks ( filter ) {
        return await this.get( `/tasks?${new URLSearchParams( filter ).toString()}` )
      },



      // CRUD /tasks

      async createArchive ( data ) {
        return await this.post( `/archive`, data )
      },
      async readArchive () {
        return await this.get( `/archive` )
      },
      async deleteArchive( ids ) {
        return await this.delete( `/archive/${ids}` )
      },




      // CRUD /statuses

      async createStatuses( data ) {
        return await this.post( `/statuses`, data )
      },

      async deleteStatuses ( ids ) {
        return await this.delete( `/statuses/${ids}` )
      },

      async readStatuses () {
        return await this.get( `/statuses` )
      }

    }

  }
}
