<template>
  <div class="d-flex align-center justify-center" style="height: 100%;">
    <v-btn @click="login">Login</v-btn>
  </div>
</template>

<script>

import { mapActions } from 'vuex'

export default {

  name: 'Login',

  data: () => ({
    username: 'root',
    password: 'nowef1'
  }),

  methods: {

    ...mapActions({
      setToken: 'setToken',
    }),

    async login () {
      let result = await this.$api.authentication({
        username: this.username,
        password: this.password
      })
      if ( result.data.access_token && result.data.user_id ) {
        this.setToken( result.data.access_token )
      }
      return result
    }

  }

}

</script>

<style scoped>

</style>
