import Vue from 'vue'
import Vuex from 'vuex'

Vue.use( Vuex )

export default new Vuex.Store({

    state: {
        token: localStorage.access_token || null,
        headers: localStorage.headers || 'actions,id,name,organization,phone,comment,start,end,status',
    },

    mutations: {
        setToken: ( state, token ) => state.token = token,
        setHeaders: ( state, headers ) => state.headers = headers
    },

    actions: {
        setToken: ( { commit }, token ) => {
            localStorage.access_token = token
            commit( 'setToken', token )
        },
        setHeaders: ( { commit }, headers ) =>{
            localStorage.headers = headers
            commit( 'setHeaders', headers )
        }
    },

    getters: {
        getToken: state => state.token,
        getHeaders: state => state.headers,
    },

    modules: {

    }

})
